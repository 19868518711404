import {Button} from '@c1/gravity-react';
import {navigate, useParams} from '@reach/router';
import {AxiosError} from 'axios';
import {ReactElement, useEffect, useState} from 'react';
import DashboardStepper from 'src/components/DashboardStepper/DashboardStepper';
import DashboardModal from 'src/components/modals/DashboardModal/DashboardModal';
import InformationModal from 'src/components/modals/InformationModal/InformationModal';
import NoResultsFound from 'src/components/NoResultsFound/NoResultsFound';
import {GetDashboardsResponse} from 'src/types/apiResponse';
import {Dashboard, DashboardType} from 'src/types/dashboard';
import {Column, GrvTypographyClass} from 'src/types/gravity';
import {FunctionComponentReturnType} from 'src/types/sharedReact';
import {getFromApi} from 'src/utils/getFromApi';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import {useRouteBuilder} from 'src/utils/routing/RouteBuilderContext';
import {createSortableColumn} from 'src/utils/tableUtils';
import 'src/pages/DashboardsPages/DashboardSelectionPage.css';
import fallback from 'src/assets/images/fallback_marketing_content.png';

interface DashboardAsDataSource extends Dashboard {
    actions?: ReactElement;
}

//extract pinboard id from the given embedded URL
function ExtractPinboardIdFromURL(dataValue : string): string {
    let dataValueSplitArray = dataValue.split("/");
    return dataValueSplitArray[dataValueSplitArray.length - 1] ? dataValueSplitArray[dataValueSplitArray.length - 1] : dataValueSplitArray[dataValueSplitArray.length - 2];
}

function DashboardSelectionPage(): FunctionComponentReturnType {
    const [data, setData] = useState<GetDashboardsResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const {accessTypeCode, partnerCode} = useParams();
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute: string = routeBuilder.api.getDashboardsForPartner();
    const apiLatestMarketing = (partnerCode: string) => routeBuilder.api.getLatestMarketingImage(partnerCode)
    const [dashboard, setDashboard] = useState<Dashboard | null>(null);
    const [sortBy, setSortBy] = useState<keyof Dashboard | null>(null);
    const [sortDirection, setSortDirection] = useState<number>(0);
    const [showMarketingModal, setShowMarketingModal] = useState<boolean>(false);

    const marketingAllowlist = process.env.REACT_APP_FLAG_MARKETING_CONTENT_PTRS.split(',');

    const columns: Column[] = [
        createSortableColumn('Name', 'dashboardName', 'dashboardName', sortBy, sortDirection, setSortBy, setSortDirection),
        { title: 'Description', dataIndex: 'dashboardDescription', key: 'dashboardDescription' },
        { title: '', dataIndex: 'actions', key: 'actions' }
    ];

    useEffect(getFromApi(apiRoute, setData, setError), [apiRoute]);

    useEffect(() => {
        let shownKey = 'copp_mobile_marketing_' + data?.partner.code;
        let marketingSeenAlready = localStorage.getItem(shownKey);
        if (!marketingSeenAlready && data && data.partner && marketingAllowlist.includes(data.partner.code)) {
            setShowMarketingModal(true);
            localStorage.setItem('copp_mobile_marketing_' + data.partner.code, 'shown');
        }
    }, [data]);

    function loadThoughtspot(dashboardUrl : string, dashboardTitle : string) {
        var pinboardId = ExtractPinboardIdFromURL(dashboardUrl)
        navigate(routeBuilder.client.toThoughtspotLiveBoardPage({
            accessTypeCode: accessTypeCode,
            partnerCode: partnerCode,
            pinboardId: pinboardId
        }), {
            state: {
                title: dashboardTitle,
                pinboarUrl : dashboardUrl
            }
        });
    }

    function loadRStudio(dashboardTitle : string, dashboardId : string) {
        navigate(routeBuilder.client.toRStudioLiveBoardPage({
            accessTypeCode: accessTypeCode,
            partnerCode: partnerCode,
            dashboardId: dashboardId,
        }), {
            state: {
                title: dashboardTitle
            }
        });
    }

    function loadQuicksight(dashboardId : string) {
        navigate(routeBuilder.client.toQuicksightPage({
            accessTypeCode: accessTypeCode,
            partnerCode: partnerCode,
            pinboardId: dashboardId
        }));
    }

    function loadTableau(dashboardUrl : string, dashboardTitle : string, dashboardId : string) {
        navigate(routeBuilder.client.toTableauPage({
            accessTypeCode: accessTypeCode,
            partnerCode: partnerCode,
            dashboardId: dashboardId
        }), {
            state: {
                title: dashboardTitle,
                dashboardUrl : dashboardUrl
            }
        });
    }

    function createDataSource(): DashboardAsDataSource[] {
        return data?.dashboards.map(d => ({
            ...d,
            actions: (
                    <div onClick={(event): void => {
                        event.preventDefault();
                        setDashboard(d);
                        if (d.dashboardType === DashboardType.THOUGHTSPOT) { loadThoughtspot(d.dashboardUrl, d.dashboardName) }
                        else if (d.dashboardType === DashboardType.QUICKSIGHT) { loadQuicksight(d.dashboardId) }
                        else if (d.dashboardType === DashboardType.RSTUDIO) { loadRStudio(d.dashboardName, d.dashboardId) }
                        // else if (d.dashboardType === DashboardType.TABLEAU) { loadTableau(d.dashboardUrl, d.dashboardName, d.dashboardId) } // DISABLE TABLEAU EMBED
                    }}
                    >
                    <div className="dashboard-tile-body">
                        <div className="dashboard-header">
                            <div className={"dashboard-title " + GrvTypographyClass.textSizeNormal}><p>{d?.dashboardName}</p></div>
                        </div>
                        <Button id={'copp-button-view-dashboard-' + d?.dashboardId} className="grv-margin--tiny" compact> view </Button>
                    </div>
                    <div className="dashboard-description">{d?.dashboardDescription}</div>
                </div>
            )
        }))
        .sort((a, b): number => {
            if (sortBy) {
                let diff: number = 0;
                const fieldA = a[sortBy];
                const fieldB = b[sortBy];

                if (typeof fieldA === 'number' && typeof fieldB === 'number') {
                    diff = fieldA - fieldB;
                } else if (fieldA !== undefined && fieldB !== undefined) {
                    diff = fieldA.toString().localeCompare(fieldB.toString());
                }

                return diff * sortDirection;
            }
            return 0;
        }) ?? [];
    }

    if (error) {
        throw error;
    }

    let rowCreator = function(dashboard: DashboardAsDataSource, id: number) {
        return <div id={"dashboard-id-" + id} className="dashboard-tile">
            {dashboard?.actions}
        </div>
    }
    let dataSource: DashboardAsDataSource[] = createDataSource();

    return (
        <>
            <DashboardStepper
                selectedAccessType={data?.partner!.type}
                selectedPartner={data?.partner!.name}
            />
            {
                data && (
                <div className="dashboard-grid">
                    {data.dashboards.length ? dataSource.map(function(object, i){
                        return rowCreator(object, i);
                        })
                    : <NoResultsFound/>}
                </div>
                )
            }
            {
                dashboard && dashboard.dashboardType !== DashboardType.THOUGHTSPOT && dashboard.dashboardType !== DashboardType.QUICKSIGHT
                && dashboard.dashboardType !== DashboardType.RSTUDIO && //dashboard.dashboardType !== DashboardType.TABLEAU && // DISABLE TABLEAU EMBED
                (
                <DashboardModal
                    onClose={() => setDashboard(null)}
                    dashboard={dashboard}
                />
                )
            }
            {
                data && data.partner && marketingAllowlist.includes(data.partner.code) &&
                <InformationModal
                    description=""
                    isOpen={showMarketingModal}
                    title=" "
                    onClose={() => setShowMarketingModal(false)}
                >
                    <img
                        src={apiLatestMarketing(data.partner.code)}
                        onError={({currentTarget}) => {
                            currentTarget.onerror = null;
                            currentTarget.src = fallback;
                        }}
                        alt="Latest Marketing Promotion"
                    />
                </InformationModal>
            }
        </>
    );
}

export default DashboardSelectionPage;
